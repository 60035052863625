<template>
  <section>
    <a class="anchor" id="about-section">&nbsp;</a>
    <div class="about">
      <v-container fluid>
        <v-row>
          <v-col md="6" cols="12" class="block">
            <div style="background: #E1EFF2; height: 100%;">
              <div class="text-h2" style="padding-top: 20px; text-transform: uppercase; font: normal normal normal 40px/1.35em raleway,sans-serif !important;">About</div>
              <br/>
              <div id="about-text-body" class="text-body" style="padding-left: 24px; padding-right: 24px;">
              <!--<p>Das Araare Pop-Up befindet sich in der unteren Altstadt in unmittelbarer Nähe des Bärenparks. Während den Sommermonaten wird im Café Lieblings neben den üblichen lokalen Kaffe- und Getränkespezialitäten auch Shaved Ice angeboten. Die Spezialitäten können entweder drinnen in gemütlichem Ambiente oder draussen an der Sonne inmitten der pulsierenden Altstadt genossen werden.</p>-->
              <p>Das Eis ist inspiriert von Japan und besteht aus fluffig geraspeltem Eis und hausgemachten Sirupen. Die leichte Textur der Eiskristalle zerschmilzt sofort im Mund und lässt sich hervorragend mit Früchten und Tee, wie z. B. Erdbeeren und Matcha, kombinieren. Diese Kombination ist der perfekte Durstlöscher im Sommer! Zusätzlich ist es kalorienarmer als die üblichen Glacés!</p>
              Araare ist entstanden durch die Wörter Aare (der Fluss) und 霰（あられ - Arare, japanisch für Hagel)
              <v-img
                  class="white--text align-end"
                  height="130px"
                  :src="currImgURL"
                  contain
                  style="margin-bottom: 20px;"
                >
              </v-img>
              <br/>
              <p>Für alle, die Laktose oder Gluten vermeiden möchten, ist Araare eine erfrischende Alternative zu klassischen Gelaterias.</p>
              </div>
            </div>
          </v-col>
          <v-col md="6" cols="12" class="block" id="about_image_block">
            <v-img style="height: 100%;" src="../assets/about_300px.jpg">
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',

  data: () => ({
    currImg: 0,
    images: [
      {
        name: 'sketch-big',
        path: require('../assets/araare-sketchwork-big.png')
      },
      {
        name: 'sketch-small',
        path: require('../assets/araare-sketchwork-small.png')
      }
    ]
  }),
  computed: {
    currImgURL: function () {
      var path
      if (screen.width < 960) {
        path = this.images[1].path
      } else {
        path = this.images[0].path
      }
      return path
    }
  }
}
</script>
