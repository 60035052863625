<template>
  <section>
    <a class="anchor" id="menu-section">&nbsp;</a>
    <div class="menue">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
             <div class="text-h2" style="text-transform: uppercase; font: normal normal normal 40px/1.35em raleway,sans-serif !important;">Menu</div>
          </v-col>
          <v-col cols="12">
            <!--<a id="menu_pdf_link" href="./pdf/araare_menu.pdf" target="_blank">Unsere Karte ansehen</a>-->
            <h3> Shaved Ice Flavours </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" cols="12">
            <v-card outlined>
              <v-img
                class="white--text align-end"
                height="300px"
                src="../assets/jp_matcha.jpg"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="text-body">Matcha | Homemade Greentea and Milk Syrup
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card outlined>
            <v-img
              class="white--text align-end"
              height="300px"
              src="../assets/jp_strawberry.jpg"
            >
            </v-img>

            <v-card-text class="text--primary">
              <div class="text-body">Strawberry | Homemade Strawberry and Milk Syrup</div>
            </v-card-text>
          </v-card>
          </v-col>
          <v-col md="4" cols="12">
            <v-card outlined>
            <v-img
              class="white--text align-end"
              height="300px"
              src="../assets/jp_lemon.jpg"
            >
            </v-img>

            <v-card-text class="text--primary">
              <div class="text-body">Lemon | Homemade Lemon Syrup</div>
            </v-card-text>
          </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Menue'
}
</script>
