<template>
        <v-app-bar
        app
        height="200px"
        shrink-on-scroll
        fade-img-on-scroll
        id="header_img"
        :class="scrollDown ? 'conditionalLogoText' : ''"
        >
          <template v-slot:img="{ props }">
            <v-img
              v-bind="props"
              src="@/assets/ARAARE_logo_rund.svg"
              contain
            ></v-img>
          </template>
          <template v-slot:extension>
            <v-tabs dark hide-slider centered show-arrows>
                <v-tab @click="scrollToTargetAdjusted('home-section')">Home</v-tab>
                <v-tab @click="scrollToTargetAdjusted('about-section')">About</v-tab>
                <!-- <v-tab><a href="#about-section" @click="scrollToTargetAdjusted('about-section')">About</a></v-tab> -->
                <v-tab @click="scrollToTargetAdjusted('menu-section')">Menu</v-tab>
                <v-tab @click="scrollToTargetAdjusted('photos-section')">Gallery</v-tab>
                <v-tab @click="scrollToTargetAdjusted('team-section')">Team</v-tab>
                <v-tab @click="scrollToTargetAdjusted('contacts-section')">Contact</v-tab>
            </v-tabs>
        </template>
        </v-app-bar>
</template>

<script>
export default {
  name: 'AppNavigation',
  data: () => ({
    scrollDown: false,
    scrollPosition: null,
    offsetPosition: 0
  }),
  methods: {
    updateScroll () {
      this.scrollPosition = window.scrollY
      this.checkScrollPosition()
    },
    // check scroll position. If 0 (page top) don't remove class conditionalLogoText to hide Logo in Navbar tabs
    checkScrollPosition () {
      if (this.scrollPosition > 100) {
        this.scrollDown = true
      } else {
        this.scrollDown = false
      }
    },
    scrollToTargetAdjusted (id) {
      this.offsetPosition = document.getElementById(id).offsetTop
      window.scrollTo(0, this.offsetPosition)
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  }
}

</script>
