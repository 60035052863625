<template>
  <section>
    <a class="anchor" id="photos-section">&nbsp;</a>
    <div class="photos">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
             <div class="text-h2" style="text-transform: uppercase; font: normal normal normal 40px/1.35em raleway,sans-serif !important;">Gallery</div>
          </v-col>
        </v-row>
        <v-row>
          <v-sheet
    class="mx-auto"
    max-width="100%"
  >
    <v-slide-group
      class="pa-4"
      show-arrows
    >
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/strawberry03.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/matcha03.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/lemon03.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/strawberry01_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/matcha01_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/lemon01_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/strawberry02_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/matcha02_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/lemon02_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/mood01_300px.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/gallery01.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/gallery02.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/gallery03.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/gallery04.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/gallery05.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/gallery06.jpg"
        >
        </v-img>
      </v-slide-item>
      <v-slide-item>
        <v-img
          class="ma-4"
          height="300"
          width="300"
          src="../assets/gallery_img/koreanstyle.jpg"
        >
        </v-img>
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Photos'
}
</script>
