<template>
  <section>
    <a class="anchor" id="team-section">&nbsp;</a>
    <div class="team">
      <div style="background: #E1EFF2; height: 100%;">
        <v-container fluid>
          <v-row>
            <v-col md="12">
                <div class="text-h2" style="text-transform: uppercase; font: normal normal normal 40px/1.35em raleway,sans-serif !important;">Team</div>
                <br/>
                <div class="text-body" style="padding-left: 24px; padding-right: 24px;">
                <p>Wir sind eine Gruppe von jungen Kaffeeliebhabern, die ständig auf der Suche nach neuen gemütlichen Lokalen sind.
                Schnell wurde uns bewusst, dass wir selber ein Kaffee führen wollen und haben das in der Form eines Sommerprojekts auf die Beine gestellt.
                </p>
                <p>Unser Ziel ist es, etwas anzubieten, was es in der Schweiz so noch nicht gibt.</p>
                </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" cols="12">
              <v-card outlined>
                <v-img
                  class="white--text align-end"
                  height="300px"
                  src="../assets/team_meiling2.jpg"
                  position="unset"
                >
                </v-img>

                <v-card-text class="text--primary">
                <div class="text-body">Mei Ling Wong</div>

                <div class="text-body">Front Office und Back Office</div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card outlined>
              <v-img
                class="white--text align-end"
                height="300px"
                src="../assets/team_maho.jpg"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="text-body">Maho Murakami</div>

                <div class="text-body">Front Office</div>
              </v-card-text>
            </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card outlined>
              <v-img
                class="white--text align-end"
                height="300px"
                src="../assets/team_meiyung.jpg"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="text-body">Mei Yung Wong</div>

                <div class="text-body">Front Office und Back Office</div>
              </v-card-text>
            </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4" cols="12">
              <v-card outlined>
              <v-img
                class="white--text align-end"
                height="300px"
                src="../assets/team_philippe.jpg"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="text-body">Philippe Lüthi</div>

                <div class="text-body">Back Office</div>
              </v-card-text>
            </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card outlined>
              <v-img
                class="white--text align-end"
                height="300px"
                src="../assets/team_simon.jpg"
                position="unset"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="text-body">Simon Koch</div>

                <div class="text-body">Back Office</div>
              </v-card-text>
            </v-card>
            </v-col>
            <v-col md="4" cols="12">
              <v-card outlined>
              <v-img
                class="white--text align-end"
                height="300px"
                src="../assets/team_natascha.jpg"
              >
              </v-img>

              <v-card-text class="text--primary">
                <div class="text-body">Natascha Jankovski</div>

                <div class="text-body">Illustratorin/Grafikerin <a href="https://www.nataschajankovski.com" target="_blank" style="color: lightgray !important;">nataschajankovski.com</a></div>
              </v-card-text>
            </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Team'
}
</script>
