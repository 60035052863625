<template>
  <section>
    <a class="anchor-home" id="home-section">&nbsp;</a>
    <div class="home">
      <div class="text-lg-h3 text-md-h4 text-sm-h4 text-h4" style="font-family: raleway,sans-serif !important;">NIHON MATSURI - das grosse Japanfest<br> <br> beim Bernischen Historischen Museum <br> <br> Samstag & Sonntag, 07./08.09.2024, 10 - 17 Uhr!!!</div>
      <br>
      <div class="text-lg-h3 text-md-h4 text-sm-h4 text-h4" style="font-family: raleway,sans-serif !important;">@  Helvetiaplatz 5, 3001 Bern</div>
      <br>
      <!--<div class="text-lg-h3 text-md-h4 text-sm-h4 text-h4" style="font-family: raleway,sans-serif !important;">@ <a href="https://goo.gl/maps/PBVLXcb6Jxk3wkpJ7" style="color: black !important;">Rain 47, 5000 Aarau</a></div>
      <v-img id="home_quote" src="../assets/wakara.jpg" alt="Wakara">
      </v-img>-->
      <div class="d-flex justify-center ma-4">
      <v-img src="../assets/nihonmatsuri23.png" alt="Nihon Matsuri Bern" max-width="60%" position="center center">
      </v-img></div>
      <a id="festival_link" href="https://www.bhm.ch/de/agenda/nihon-matsuri-das-grosse-japanfest?date=308" target="_blank">Nihon Matsuri Webseite</a>
      <!--<br>
      <br>
      <br>
      <br>
      <div class="text-lg-h3 text-md-h4 text-sm-h4 text-h4" style="font-family: raleway,sans-serif !important;">Nächstes Event: Nihon Matsuri beim Bernischen Historischen Museum</div>
      <br>
      <div class="text-lg-h3 text-md-h4 text-sm-h4 text-h4" style="font-family: raleway,sans-serif !important;">@ Helvetiaplatz 5, 3001 Bern</div>
      <br>
      <div class="text-md-h4 text-sm-h4 text-h5" style="font-family: raleway,sans-serif !important;">Inspiriert von Japan</div> -->
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>
</style>
