<template>
  <section class="section-panel">
    <a class="anchor" id="contacts-section">&nbsp;</a>
    <div class="contacts">
      <v-container style="background: lightgrey;" fluid>
        <v-row>
          <!-- <v-col md="6" cols="12" class="block">
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2723.6765622446296!2d7.452876315804035!3d46.94839564120561!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e39dcb8d27ec1%3A0xa30e54a1f59290b5!2sLieblings!5e0!3m2!1sde!2sch!4v1617912752176!5m2!1sde!2sch" style="border:0; height: 100%; width: inherit;" allowfullscreen="" loading="lazy"></iframe>
          </v-col> -->
          <v-col md="12" cols="12" class="block">
            <div style="height: 100%">
              <div class="text-h2" style="text-transform: uppercase; font: normal normal normal 40px/1.35em raleway,sans-serif !important;">Contact</div>
              <br/>
              <div class="text-body" >inthesummer@araare.be</div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contacts'
}
</script>
