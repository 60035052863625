<template>
    <div id="lab_social_icon_footer">
        <div class="container">
            <div class="text-center center-block">
                <a href="https://www.facebook.com/araare.be/" target="_blank"><i id="social-fb" class="fa fa-facebook-square fa-3x social"></i></a>
                <a href="https://www.instagram.com/araare.be" target="_blank"><i id="social-inst" class="fa fa-instagram fa-3x social"></i></a>
                <a href="mailto:inthesummer@araare.be" target="_blank"><i id="social-em" class="fa fa-envelope-square fa-3x social"></i></a>
            </div><br>
            <p style="font-size: 10px; color: #a8a8a8;">Araare | inthesummer@araare.be | 2023</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Footers'
}
</script>
