<template>
  <v-app>
      <app-navigation></app-navigation>
        <v-main transition="slide-x-transition">
          <v-banner color="#2e7dbc" sticky style="color: white;">*** UPDATE: Nihon Matsuri beim Historischen Museum Bern 07./08.09.2024***</v-banner>
          <home></home>
          <about></about>
          <menue></menue>
          <photos></photos>
          <team></team>
          <contacts></contacts>
          <footers></footers>
        </v-main>
  </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation'
import Home from './views/Home.vue'
import About from './views/About.vue'
import Footers from './views/Footer.vue'
import Contacts from './views/Contacts.vue'
import Menue from './views/Menu.vue'
import Team from './views/Team.vue'
import Photos from './views/Photos.vue'

export default {
  name: 'App',
  metaInfo: {
    title: 'Araare | Shaved Ice Café in Bern',
    meta: [
      { name: 'araare', content: 'Welcome to the first shade ice cafe in Bern. We are located in the old town of Bern and offer a variety of shaved ice and beverages.' },
      { property: 'og:title', content: 'Araare | Shaved Ice Café in Bern' },
      { property: 'og:site_name', content: 'Araare' },
      { property: 'og:type', content: 'website' },
      { name: 'robots', content: 'index,follow' }
    ]
  },
  components: {
    AppNavigation,
    Home,
    About,
    Menue,
    Photos,
    Team,
    Contacts,
    Footers
  }

}
</script>

<style>
  @import './assets/styles/stylesheet.css';
</style>
